import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  background-color: #ffffff;

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));

  margin: 35px auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    max-width: 608px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  span {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
  }
`;

export const Image = styled.img`
  max-width: 481px;
  height: 361px;

  @media screen and (max-width: 900px) {
    max-width: none;
    width: 100%;
    height: auto;
  }
`;

export const CompletedImage = styled.img`
  left: -6px;
  position: relative;
  top: -6px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 361px;

  position: relative;

  padding: 40px 25px 30px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  color: #003064;

  margin-bottom: 39px;
`;

export const Funded = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #1A1A18;

  margin-bottom: 24px;
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 720px) {
    margin-bottom: 50px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1A1A18;

  margin-bottom: 2px;
`;

export const PendingSum = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1A1A18;
  margin-left: 3px;
`;

export const ItemValue = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #003064;

  margin-bottom: 2px;
`;

export const Links = styled.div`
  position: absolute;
  height: 55px;
  left: 40%;
  right: 0;
  bottom: 0;

  font-size: 18px;
  line-height: 60px;

  background-image: linear-gradient(to bottom right, transparent 49%, #003064 50%);

  > span {
    position: absolute;
    right: 20px;
    bottom: -8px;
    cursor: pointer;
  }

  & a {
    color: #FFFFFF !important;
    text-decoration-line: none;
    font-weight: normal;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 60px;
    left: 0;
  }
`;

export const LinkWrapper = styled.div`
  > a {
    text-decoration-line: none;

    & :hover {
      text-decoration-line: underline;
    }
  }
`;
